// Angulars
import { Routes } from '@angular/router';

// Guards
import { AuthGuard } from './guards/auth.guard';

// Layouts
import { BlankLayoutComponent } from './layouts/blank-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },

      /* EXAMPLES */
      { path: 'examples', loadChildren: () => import('./components/examples/example.module').then(m => m.ExampleModule) },

      { path: 'crawling', loadChildren: () => import('./components/crawlings/crawling.module').then(m => m.CrawlingModule) }
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [{ path: 'login', loadChildren: () => import('./components/authentications/login.module').then(m => m.LoginModule) }],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{ path: '**', loadChildren: () => import('./page-not-found.module').then(m => m.PageNotFoundModule) }],
  },
];
