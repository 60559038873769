import { environment } from '../../environments/environment';

export class ApiCollection {
  public static readonly AUTH_LOGIN = environment.apiUrl + 'v1/administrator/auth/login';
  public static readonly AUTH_LOGIN_WITH_FIREBASE_AUTH_TOKEN = environment.apiUrl + 'v1/auth/login_with_firebase_auth_token';
  public static readonly AUTH_LOGOUT = environment.apiUrl + 'v1/administrator/auth/logout';
  public static readonly AUTH_INFO = environment.apiUrl + 'v1/administrator/auth/info';

  public static readonly PRODUCTS = environment.apiUrl + 'v1/administrator/products';
  public static readonly CATEGORIES = environment.apiUrl + 'v1/administrator/categories';
  public static readonly BRANDS = environment.apiUrl + 'v1/administrator/brands';
  public static readonly SHOPS = environment.apiUrl + 'v1/administrator/shops';
  public static readonly PRICE_LIST = environment.apiUrl + 'v1/administrator/price_list';

  public static readonly LINKS = environment.apiUrl + 'v1/administrator/links';
  public static readonly LINK_TYPES = environment.apiUrl + 'v1/administrator/link_types';  
  public static readonly LINK_HISTORIES = environment.apiUrl + 'v1/administrator/link_histories';
  
}
