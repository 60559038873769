// Angulars
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ChangeDetectorRef, AfterViewChecked, Renderer2, Inject, PLATFORM_ID, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

// Libraries
import { Store } from '@ngxs/store';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './services/local-storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';

// PrimeNG Libraries
import { DialogService, DynamicDialogComponent, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

// Helpers
import { UtilHelper } from './helpers/util.helper';

// Services
import { AuthService } from './services/auth.service';

// Components
import { AlertDialogComponent } from './shared/components/dialog/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './shared/components/dialog/confirm-dialog/confirm-dialog.component';

// Actions
import { Info, Logout } from './modules/auth/auth.action';

// Models
import { Sidebar } from './models/sidebar';
import { Model } from './models/model';

// States
import { AuthState } from './modules/auth/auth.state';

// Configs
import { environment } from '../environments/environment';
import { Config } from './config/config';
import { RouteCollection } from './config/route.collection';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [MessageService, DialogService],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit, AfterViewChecked {
  static isBrowser = new BehaviorSubject<boolean>(true);

  // Config
  config = Config;
  routeCollection = RouteCollection;

  // Page
  currentPage = '';

  // Device Detector
  deviceInfo: any = null;

  // Model
  model: any[] = [];
  models: Model[] = [];

  // Menu
  isMenubarItemTranslated = false;
  sidebar: Sidebar[] = [];

  constructor(
    protected router: Router,
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private store: Store,
    public translateService: TranslateService,
    protected localStorage: LocalStorageService,
    protected dialogService: DialogService,
    private messageService: MessageService,
    library: FaIconLibrary,
    private deviceService: DeviceDetectorService,
    public spinner: NgxSpinnerService,
    protected util: UtilHelper,
    protected auth: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cdRef: ChangeDetectorRef,
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    library.addIconPacks(fas, far);

    this.initLang();

    this.loadAuth();

    this.sidebar['left'] = new Sidebar();
  }

  ngOnInit(): void {
    this.hideLoading();
    if (environment.production) {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'ng-version');
    }

    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Fungsi yang ditrigger ketika window di-resize
   * @param event
   */
  //@HostListener('window:resize', ['$event']) onResize(event) { }

  /**
   * Fungsi yang ditrigger ketika window di-scroll
   */
  //@HostListener('window:scroll', []) onWindowScroll() { }

  /**
   * Fungsi yang ditrigger ketika mouse digerakkan
   */
  @HostListener('mousemove', ['$event']) onMousemove(event: MouseEvent) {
    if (this.deviceService.isDesktop()) {
      // Jika posisi X mouse = 0, maka tampilkan sidebar
      if (event.screenX <= 0) {
        this.sidebar['left'].show = true;
      }

      // Jika posisi X mouse > lebar sidebar, maka sembunyikan sidebar
      else if (event.screenX > this.sidebar['left'].width) {
        this.sidebar['left'].show = false;
      }
    }
  }

  /**
   *
   * @returns Fungsi untuk inisialisasi bahasa
   */
  initLang(): Promise<any> {
    return new Promise((resolve: Function) => {
      this.translateService.addLangs(['en', 'id']);

      if (!localStorage.getItem('lang')) {
        this.localStorage.setItem('lang', this.config.DEFAULT_LANG);
        this.translateService.setDefaultLang(this.config.DEFAULT_LANG);
      } else {
        this.translateService.setDefaultLang(this.localStorage.getItem('lang'));
      }
    });
  }

  /**
   * Fungsi untuk mengganti bahasa
   */
  switchLang(lang: string) {
    this.translateService.use(lang);
  }

  /**
   * Fungsi untuk mengambil data auth
   */
  loadAuth() {
    let authorization = this.store.selectSnapshot(AuthState.authorization);

    if (authorization) {
      this.auth.setUser(this.store.selectSnapshot(AuthState.user));
      this.auth.setPermission(this.store.selectSnapshot(AuthState.permissions));

      // Atur ulang akses
      this.showLoading();
      this.store.dispatch(new Info()).subscribe(result => {
        this.hideLoading();

        this.auth.setUser(result.auth.user);
        this.auth.setPermission(result.auth.permissions);
      });
    }
  }

  /**
   * Fungsi untuk menampilkan pesan alert
   * @param type
   * @param messages
   */
  showAlert(type: any, messages: any) {
    let content = '';

    if (typeof messages == 'object') {
      content = '<ul>';
      for (let message of messages) {
        if (message.param) {
          content += '<li>' + message.param + ' ' + message.message.toLowerCase() + '</li>';
        } else if (message.message) {
          content += '<li>' + message.message + '</li>';
        } else {
          content += '<li>' + message + '</li>';
        }
      }
      content += '</ul>';
    } else {
      content = messages;
    }

    const ref = this.dialogService.open(AlertDialogComponent, {
      showHeader: false,
      width: '450px',
      closeOnEscape: true,
      dismissableMask: false,
      modal: true,
      styleClass: 'alert-dialog',
      data: {
        type: type,
        content: content,
      },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  }

  /**
   * Fungsi untuk menampilkan pesan konfirmasi
   * @param messages
   * @returns
   */
  showConfirmation(messages: any): Observable<any> {
    let content = '';

    if (typeof messages == 'object') {
      content = '<ul>';
      for (let message of messages) {
        if (message.param) {
          content += '<li>' + message.param + ' ' + message.message.toLowerCase() + '</li>';
        } else if (message.message) {
          content += '<li>' + message.message + '</li>';
        } else {
          content += '<li>' + message + '</li>';
        }
      }
      content += '</ul>';
    } else {
      content = messages;
    }

    const ref = this.dialogService.open(ConfirmDialogComponent, {
      showHeader: false,
      width: '450px',
      closeOnEscape: true,
      dismissableMask: false,
      modal: true,
      styleClass: 'confirm-dialog',
      data: {
        content: content,
      },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    return ref.onClose;
  }

  /**
   * Fungsi untuk menampilkan pesan toast
   * @param type
   * @param messages
   */
  showToast(type: any, messages: any) {
    let text = '';

    if (typeof messages == 'object') {
      text = '<ul>';
      for (let message of messages) {
        if (message.param) {
          text += '<li>' + message.param + ' ' + message.message.toLowerCase() + '</li>';
        } else if (message.message) {
          text += '<li>' + message.message + '</li>';
        } else {
          text += '<li>' + message + '</li>';
        }
      }
      text += '</ul>';
    } else {
      text = messages;
    }

    this.messageService.add({ severity: type, detail: text });
  }

  /**
   * Fungsi untuk memunculkan animasi loading
   */
  showLoading() {
    this.spinner.show();
  }

  /**
   * Fungsi untuk menyembunyikan animasi loading
   */
  hideLoading() {
    this.spinner.hide();
  }

  /**
   * Fungsi untuk logout
   */
  logout() {
    this.showLoading();
    this.store.dispatch(new Logout()).subscribe(result => {
      this.hideLoading();

      this.auth.removeUser();

      this.router.navigate([RouteCollection.LOGIN]);
    });
  }
}
